import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleLink from '../components/article-link';

import './post-list.scss';

const PostList = ({ data: { allWpPost: { edges: posts } } }: any) => (
  <Layout scheme="dark" categories="Videos">
    <SEO
      title="Videos"
      description="View all Skin Deep videos"
      metaTitle="Videos"
    />
    <div className="post-list">
      {posts.map(({ node: { title, slug, featuredImage } }: any) => (
        <ArticleLink
          title={title}
          imageData={featuredImage?.node.localFile}
          slug={slug}
          key={slug}
        />
      ))}
    </div>
  </Layout>
);

export default PostList;

export const postQuery = graphql`
query videoQuery {
  allWpPost(filter: {featuredVideo: {featuredVideo: {ne: null}}}) {
    edges {
      node {
        title
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 314
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  aspectRatio: 1.7375
                )
              }
            }
          }
        }
      }
    }
  }
}
`;
